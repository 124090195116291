<template>
  <!-- branch:finder START -->
  <div class="account-branch-finder">

    <!-- branch-finder:actions START -->
    <div class="actions">
      <div class="actions-inner">
        <ul>
          <li
            v-on:click="
              triggerList();
              triggerCurrentLocation(false);
              activeAction = activeAction !== 'list' ? 'list' : null"
            :class="[
              { 'is-open': activeAction === 'list' }
            ]"
          >
            <img :src="iconAccountList" />
          </li>
          <li
            v-on:click="
              triggerSearch();
              triggerCurrentLocation(false);
              activeAction = activeAction !== 'search' ? 'search' : null"
            :class="[
              { 'is-open': activeAction === 'search' }
            ]"
          >
            <img :src="iconAccountSearch" />
          </li>
          <li
            v-on:click="
              triggerCurrentLocation(true);
              activeAction = activeAction !== 'location' ? 'location' : null"
            :class="[
              'pulse',
              { 'is-open': loadingActivated || currentLocation }
            ]"
          >
            <img :src="iconAccountCurrentLocation" />
          </li>
        </ul>
      </div>
    </div>
    <!-- branch-finder:actions END -->

    <!-- branch-finder:map START -->
    <div id="map" class="map"></div>
    <!-- branch-finder:map END -->

    <!-- branch-finder:visible-markers START -->
    <div
      id="results-list-holder"
      :class="[
        'results-list',
        { 'active' : activeList }
      ]"
    >
      <markers
        :markers="finalVisibleMarkers"
        :currentLocation="currentLocation"
        :centerMapToMarker="centerMapToMarker"
        :trigger="triggerList"
      />
    </div>
    <!-- branch-finder:visible-markers END -->

    <!-- branch-finder:search-markers START -->
    <div
      id="search-list-holder"
      :class="[
        'search-list',
        { 'active' : activeSearch }
      ]"
    >
      <ul class="filter-location">
        <li>
            <label for="filter-location" v-html="$t('location.placepostcode')"></label>
          <input name="filter-location" type="text" v-model="filterLocation" />
        </li>
      </ul>
      <markers
        :markers="filteredMarkers"
        :currentLocation="currentLocation"
        :centerMapToMarker="centerMapToMarker"
        :trigger="triggerSearch"
      />
    </div>
    <!-- branch-finder:search-markers END -->

    <!-- branch-finder:loading START -->
    <loading-default
      :loadingVisible="loadingVisible"
      :headline="loadingHeadline"
      :excerpt="loadingExcerpt"
    />
    <!-- branch-finder:loading END -->
  </div>
  <!-- branch:finder END -->
</template>

<script>
// import titleMixin from '@/mixins/titleMixin';
import { Geolocation } from '@capacitor/geolocation';
import ICONS from '../utils/icons';

let {
  google,
  MarkerClusterer,
} = window;

const {
  iGoogleMarker,
  iAccountList,
  iAccountSearch,
  iAccountCurrentLocation,
  iGoogleClusterM1,
  iGoogleClusterM2,
  iGoogleClusterM3,
  iGoogleClusterM4,
  iGoogleClusterM5,
  iLocationGoogleMarker,
} = ICONS;

export default {
  name: 'branch-finder',
  // mixins: [titleMixin],
  components: {
    Markers: () => import('../components/branch-finder/Markers.vue'),
    LoadingDefault: () => import('../components/shared/loading/Default.vue'),
  },
  data() {
    return {
      /**
       * Title (Page) - If "pageTitleFrom" exists - show simple Navigation...
       */
      // pageTitleFromComponent: this.$t('branchfinder.headline'),

      /**
       * Loading
       */
      loadingVisible: true,
      loadingActivated: false,
      loadingHeadline: this.$t('loading.location.headline'),
      loadingExcerpt: this.$t('loading.location.excerpt'),

      /**
       * Actions (Icons)
       */
      iconAccountList: iAccountList.defaultUrl,
      iconAccountSearch: iAccountSearch.defaultUrl,
      iconAccountCurrentLocation: iAccountCurrentLocation.defaultUrl,

      /**
       * Actions (Active Tab)
       */
      activeList: false,
      activeAction: null,
      activeSearch: false,

      /**
       * Actions (Search/Filter)
       */
      filterLocation: '',

      /**
       * Google Maps
       */
      locations: null,
      map: '',
      markers: [],
      infoWindow: '',
      currentZoom: 0,
      visibleMarkers: [],
      currentLocation: null,
      routerLinkActive: false,
      options: {
        zoom: 6,
        center: new google.maps.LatLng(47.850193, 13.196229),
        disableDefaultUI: true,
        styles: [
          { stylers: [{ hue: '#7FB0B2' }, { saturation: 16 }] },
          { featureType: 'water', stylers: [{ color: '#F7F7F7' }] },
          { featureType: 'all', elementType: 'labels', stylers: [{ visibility: 'off' }] },
          { featureType: 'administrative', elementType: 'labels', stylers: [{ visibility: 'on' }] },
          { featureType: 'road', elementType: 'geometry', stylers: [{ visibility: 'on' }, { color: '#FFFFFF' }] },
          { featureType: 'transit', elementType: 'all', stylers: [{ visibility: 'off' }, { color: '#FFFFFF' }] },
          { featureType: 'landscape', elementType: 'all', stylers: [{ hue: '#7FB0B2' }, { saturation: 0, lightness: -20 }] },
        ],
      },
    };
  },
  async created() {
    this.locations = await this.FETCH_LOCATIONS();

    if (this.locations) {
      // reinit google & MarkerClusterer
      google = window.google;
      MarkerClusterer = window.MarkerClusterer;

      // init map
      this.map = new google.maps.Map(document.getElementById('map'), this.options);

      // current location
      this.getCurrentLocation();

      // create & get markers
      this.createMarkers();
      this.getMarkers();

      // init blank infoWindow
      this.infoWindow = new google.maps.InfoWindow({ content: '' });

      // clear cart
      // if (this.$store.getters.storeCart.items !== 'undefined') {
      //   this.$store.dispatch('clearCart');
      // }
    }
  },
  computed: {
    finalVisibleMarkers() {
      if (this.visibleMarkers.length) {
        const { visibleMarkers } = this;
        return visibleMarkers
          .sort((a, b) => a.locationData.finalDistanceNumber - b.locationData.finalDistanceNumber);
      }
      return this.visibleMarkers;
    },

    filteredMarkers() {
      if (this.filterLocation && this.filterLocation.length >= 3) {
        return this.finalVisibleMarkers
          .filter((marker) => marker.locationData.city.toLowerCase()
            .includes(this.filterLocation.toLowerCase())
            || marker.locationData.zip.toString().indexOf(this.filterLocation) !== -1);
      }
      return [];
    },
  },
  methods: {
    async FETCH_LOCATIONS() {
      await this.$store.dispatch('setLocations');
      return this.$store.getters.locations;
    },

    createMarkers() {
      const self = this;
      this.markers = this.locations.map((location, i) => {
        self.routerLinkActive = false;
        let infoWindowContent = `
          <div onclick="self.callLocation(${location.id});" class="list-permalink"></div>
          <div class="marker-inner">
            <div class="marker-inner-description">`;
        infoWindowContent += `
              <h2> ${location.city}</h2>
              <p><span>${location.street} ${location.houseNumber}</span></p>
              <p><span>${location.zip} ${location.city}</span></p>`;
        if (location.comingsoon === 0) {
          infoWindowContent += `<p class="active"><span>${self.$t('location.open')}</span></p>`;
        } else {
          infoWindowContent += `
              <p class="inactive"><span>${self.$t('location.closed')}</span></p>`;
        }

        infoWindowContent += `
            </div>
          </div>`;

        const marker = new google.maps.Marker({
          icon: {
            url: iGoogleMarker.defaultUrl,
            size: new google.maps.Size(32, 32),
            scaledSize: new google.maps.Size(32, 32),
          },
          position: {
            lat: Number(location.lat),
            lng: Number(location.lng),
          },
          locationData: location,
          name: `Marker n. ${i}`,
          info: infoWindowContent,
          id: i + 1,
        });
        // eslint-disable-next-line
        google.maps.event.addListener(marker, 'click', function () {
          self.routerLinkActive = true;
          // pan map to the marker
          self.map.panTo(this.getPosition());
          self.map.setZoom(11);
          // open info window above marker
          setTimeout(() => {
            self.infoWindow.setContent(this.info);
            self.infoWindow.open(self.map, this);
          }, 150);
        });
        return marker;
      });
      MarkerClusterer = new MarkerClusterer(this.map, this.markers, {
        styles: [
          {
            url: iGoogleClusterM1.defaultUrl,
            textColor: '#0D3E5C',
            textSize: 18,
            anchorText: [2, 0],
            height: 38,
            width: 38,
          },
          {
            url: iGoogleClusterM2.defaultUrl,
            textColor: '#0D3E5C',
            textSize: 18,
            anchorText: [2, 0],
            height: 38,
            width: 38,
          },
          {
            url: iGoogleClusterM3.defaultUrl,
            textColor: '#0D3E5C',
            textSize: 18,
            anchorText: [2, 0],
            height: 38,
            width: 38,
          },
          {
            url: iGoogleClusterM4.defaultUrl,
            textColor: '#0D3E5C',
            textSize: 18,
            anchorText: [2, 0],
            height: 38,
            width: 38,
          },
          {
            url: iGoogleClusterM5.defaultUrl,
            textColor: '#0D3E5C',
            textSize: 18,
            anchorText: [2, 0],
            height: 38,
            width: 38,
          },
        ],
      });
    },

    getMarkers() {
      const self = this;
      google.maps.event.addListener(self.map, 'idle', () => {
        // delete previously added items
        self.visibleMarkers = [];
        // Iterate through all of the markers that are displayed on the *entire* map.
        for (let i = 0; i < self.markers.length; i += 1) {
          const currentMarker = self.markers[i];
          // add final distance to the marker
          this.getDistance(currentMarker);
          // push marker to the array
          self.visibleMarkers.push(currentMarker);
        }
      });
    },

    centerMapToMarker(id) {
      for (let i = 0; i < this.markers.length; i += 1) {
        if (this.markers[i].id === id) {
          const thisMarker = this.markers[i];
          // pan map to the marker
          this.map.panTo(thisMarker.getPosition());
          this.map.setZoom(11);
          // open info window above marker
          setTimeout(() => {
            this.infoWindow.setContent(thisMarker.info);
            this.infoWindow.open(this.map, thisMarker);
          }, 150);

          return false;
        }
      }
      return true;
    },

    scrollToTop() {
      window.scrollTo(0, 0);
    },

    triggerList() {
      // close filtered markers
      this.activeSearch = false;
      // toggle visible markers
      this.activeList = !this.activeList;
      setTimeout(() => {
        document.getElementById('results-list-holder').scrollTo(0, 0);
      }, 350);
    },

    triggerSearch() {
      // close visible markers
      this.activeList = false;
      // toggle filtered markers
      this.activeSearch = !this.activeSearch;
      // clear search model
      this.filterLocation = '';
      setTimeout(() => {
        document.getElementById('search-list-holder').scrollTo(0, 0);
      }, 350);
    },

    triggerCurrentLocation(loading) {
      if (loading) {
        this.loadingVisible = true;
        this.getCurrentLocation(true);
        setTimeout(() => {
          this.loadingVisible = false;
          this.loadingActivated = true;
        }, 1050);
      }
      if (!loading && this.loadingActivated) {
        this.getCurrentLocation(false);
      }
      if (!loading) {
        this.getCurrentLocation(false);
      }
    },

    async getCurrentLocation(activeLoading) {
      // close loading dialog
      this.loadingVisible = false;

      const coordinates = await Geolocation.getCurrentPosition();

      const permissions = await Geolocation.checkPermissions();

      if (permissions.location === 'granted' && coordinates) {
        if (activeLoading !== false) {
          // open loading dialog
          this.loadingVisible = true;
        }

        const pos = {
          lat: coordinates.coords.latitude,
          lng: coordinates.coords.longitude,
        };
        const convertedCenter = new google.maps.LatLng(
          parseFloat(pos.lat),
          parseFloat(pos.lng),
        );
        this.currentLocation = convertedCenter;

        if (activeLoading !== false) {
          this.map.setCenter(pos);
        }

        const marker = new google.maps.Marker({
          icon: {
            url: iLocationGoogleMarker.defaultUrl,
            size: new google.maps.Size(24, 29),
            scaledSize: new google.maps.Size(24, 29),
          },
          zIndex: 99999999,
          position: pos,
          map: this.map,
        });
        if (marker) {
          marker.setMap(this.map);
        }
      } else {
        Geolocation.requestPermissions();
      }

      if (this.currentLocation) {
        if (activeLoading !== false) {
          this.map.setCenter(this.currentLocation);
        }

        setTimeout(() => {
          this.fitBounds();

          // close loading dialog
          this.loadingVisible = false;
        }, 1750);
      }
    },

    getDistance(location) {
      const self = this;
      const service = new google.maps.DistanceMatrixService();
      const currentMarker = location.locationData;
      const convertedCenter = new google.maps.LatLng(
        parseFloat(currentMarker.lat),
        parseFloat(currentMarker.lng),
      );
      const convertedCurrentLocation = JSON.parse(JSON.stringify(self.currentLocation));

      if (convertedCurrentLocation && convertedCenter) {
        service.getDistanceMatrix({
          origins: [convertedCurrentLocation],
          destinations: [convertedCenter],
          travelMode: google.maps.TravelMode.DRIVING,
          unitSystem: google.maps.UnitSystem.METRIC,
          avoidHighways: false,
          avoidTolls: false,
        }, (response) => {
          const distanceValue = (response.rows[0].elements[0].distance.value / 1000)
            .toFixed(1)
            .replace('.0', '')
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
          currentMarker.finalDistance = `${distanceValue} km`;
          currentMarker.finalDistanceNumber = Number(distanceValue);
        });
      }
    },

    fitBounds() {
      if (this.finalVisibleMarkers.length
        && this.finalVisibleMarkers[0].locationData.finalDistanceNumber) {
        // dynamically zoom depending on the distance of the first two locations
        const FVM = this.finalVisibleMarkers
          .sort((a, b) => a.locationData.finalDistanceNumber - b.locationData.finalDistanceNumber);

        const nearPlace = new google.maps.LatLng(
          parseFloat(FVM[1].locationData.lat),
          parseFloat(FVM[1].locationData.lng),
        );

        const bounds = new google.maps.LatLngBounds();
        bounds.extend(this.currentLocation);
        bounds.extend(nearPlace);
        this.map.fitBounds(bounds);
      }
    },
  },
  mounted() {
    // attach function to the window (have to reach vue-router from the Google infoWindow)
    window.callLocation = (locationId) => {
      this.$router.push(`/branch-finder/${locationId}`);
    };

    // scroll to the map when route changes
    this.scrollToTop();

    // close $notify from Payback Screen
    this.$notify.closeAll();
  },
  watch: {
    visibleMarkers(val) {
      return val;
    },
  },
};
</script>
